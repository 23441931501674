<template>
    <div class="row">
        <div class="col-12 text-center">
            <div class="top_profile">
                <div class="cimg">
                    <img class="mb-2" :src="teacher && teacher.profile_image ? teacher.profile_image : '' " :alt="teacher.name"/>
                </div>
                <h3 class="name">
                    {{ teacher.name ? teacher.name : "no nickname" }}
                </h3>
            </div>
        </div>
        <div class="col-12 mb-2">
            <div class="rate">
                <div class="d-flex justify-content-between ">
                    <div class="reviewed-star star d-flex">
                        <star-rating
                            :rating="Number(teacher && teacher.avg_rating ? teacher.avg_rating : 0)"
                            :star-size="22"
                            :show-rating="false"
                            :read-only="true"
                        />
                        {{ _totalRate(teacher && teacher.avg_rating ? teacher.avg_rating : 0) }}
                        ({{ teacher && teacher.rate_qty ? teacher.rate_qty : 0 }})
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 my-2">
            <div class="text-intro  text-justify" style="white-space: pre-line !important;"
                v-if="teacher && teacher.introduction" >
                {{ teacher.introduction }}
            </div>
        </div>
        <RegisterModal
            :isVisible="this.isVisible"
            @cancel="actionCancel"
        />
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "ProfileWithRate",
    data() {
        return {
            isVisible: false,
            addFavoriting: false
        }
    },
    async created() {
        if (!this.member) {
            await this.getMMSMember();
        }
        let teacherId = this.$route.params.teacherId;
        const memberId = this.member && this.member.id ? this.member.id : 0;
        await this.fetchTeacherProfile({ id: teacherId, member_id: memberId });
    },
    computed: {
        ...mapGetters({
            member: "getMember",
            teacher: "mypage/getMypageProfile",
        }),
    },
    methods: {
        ...mapActions({
            fetchTeacherProfile: "mypage/fetchMypageProfile",
            actionUpdateFavorite: "mypage/updateFavorite",
            getMMSMember: "getMMSMember",
        }),
        actionCancel() {
            this.isVisible = false;
        },
        _totalRate(rate) {
            rate = rate.toString().slice(0, 3);
            return rate;
        },
    },
};
</script>

<style scoped>
    .bi-heart-fill {
        color: red;
        cursor: pointer;
    }
</style>